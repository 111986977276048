<template>
    <t-button v-if="!userinfo" style="border-radius: 5px; margin-right: 20px;" variant="text" shape="square"
        class="register-but" @click="linkTo('/register')">
        登录
    </t-button>

    <t-dropdown class="headimg-box" v-else direction="left" :options="userOptions" @click="clickDropdown">
        <div>
            <t-avatar class="headimg" :image="userinfo.headimgurl" size="large" shape="round" />
        </div>
    </t-dropdown>

    <OrderListShow :isOpen="isOpenOrderList" @ternOff="isOpenOrderList = false"></OrderListShow>
</template>

<script>
import { userstore } from "@/store/userinfo.js"
import { baseUrl } from "@/tools/tool"
import OrderListShow from "../OrderListShow.vue"

export default {
    data() {
        return {
            store: userstore(),
            userinfo: null,
            userOptions: [
                {
                    content: '我的订单',
                    value: 1
                },
                {
                    content: '退出登录',
                    value: 2
                },
            ],
            isOpenOrderList: false
        }
    },
    components: {
        OrderListShow
    },
    async mounted() {
        await this.store.updateUserInfo()
    },
    watch: {
        'store.userinfo': {
            handler(n) {
                this.userinfo = n
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        clickDropdown(e) {
            if (e.value == 2) { // 退出登录
                userstore().clearRegisterInfo()
                window.location.replace(baseUrl == '/api' ? 'http://localhost:8080/ ' : 'https://www.duoyinchina.com');
            } else if (e.value == 1) {
                this.isOpenOrderList = true
            }
        },
        linkTo(e) {
            if(this.$route.path !== e){
                this.$router.push(e)
            }
        }
    }

}
</script>

<style lang="less" scoped>
.headimg-box {
    display: flex;
    align-items: center;


    .headimg {
        margin-left: 10px;
        cursor: pointer;
        margin-right: 6px;
    }

    .headimg-info {
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
    }
}

.register-but {
    width: 60px;
    background-color: #ffffff;
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    margin-left: 30px;
}

.register-but:hover {
    background-color: var(--theme-color);
    color: #ffffff;
}
</style>