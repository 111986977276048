/* eslint-disable */
import { soundPlayer } from "@/store/soundplayer";
import { TestAssets } from "./assits";

export class ListeningPitchFirst {
  constructor(div, page) {
    TestAssets.questionInfoInit(this, div, page);
    this.tempoNotes = []
  }
  InitQuestion() {
    this.questionIntros = PrimaryQuestionIntros(this);
    this.questionIntro = this.questionIntros.find(
      (item) => item.value == this.level
    );

    this.notesBox = this.questionIntro.notesBox; // 当前级别需要的音符数组

    TestAssets.InitVariate(this);
    this.makeAnswerBox(); // 根据随机音符生成答题选项卡
  }
  makeAnswerBox() {

    const indexBox = ["A", "B", "C", "D"]; // 选项卡
    let num = makeNumByPage(this);
    const notes = TestAssets.makeNotes(this.notesBox, num); // 根据参数音符，随机返回3个其他音符，并组成选项卡音符数组

    let answerBox = [];

    const option = Math.floor(Math.random() * notes.length); // 答案所在的下标

    this.notes = notes[option];

    this.tempoNotes = makeTempoNotes(this.notes, num)

    for (let i = 0; i < notes.length; i++) {
      // 选项卡属性确定，index是ABCD选项，value是音符或是答案，label是序号，往往和index一样，isCorrect选项是是否是正确答案
      answerBox.push({
        index: indexBox[i],
        value: notes[i].join(", "),
        label: `${indexBox[i]}`,
        isCorrect: option == i,
      });
    }

    const div = this.div;

    this.answerBox = answerBox;

    setTimeout(() => {
      // 打印音符
      for (let i = 0; i < 4; i++) {
        if (
          this.page == "ListeningIntervalPrimary" ||
          this.page == "ListeningHarmonyPrimary" ||
          this.page == "SeniorListeningHarmony" ||
          this.page == "SeniorListeningInterval"
        ) {
          TestAssets.NotePrinterSenior(div[i], [
            {
              note: [...notes[i]],
              duration: "4",
              count: {
                num: 4,
              },
            },
          ]);
        } else {
          let tempoNotes = makeTempoNotes(notes[i], num)
          TestAssets.NoteTempoPrinter(div[i], tempoNotes, '');
        }
      }
    }, 0);
  }
  judgmente() {
    TestAssets.judgmente(this.answer, this.answerBox, this.question_num, this);
  }
  finished() {
    TestAssets.finished(
      this.score,
      this.level,
      this.questionIntros.length,
      this
    );
  }
  rateChange(e) {
    // 更改答题难度
    TestAssets.rateChange(e, this.level, this.questionIntros, this);
  }
  makeNotePlay() {
    const that = this;
    const handler = (isPlaying) => {
      that.isPlaying = isPlaying;
    };

    if (
      this.page == "ListeningIntervalPrimary" ||
      this.page == "ListeningHarmonyPrimary" ||
      this.page == "SeniorListeningHarmony" ||
      this.page == "SeniorListeningInterval"
    ) {
      soundPlayer().makeHarmonyPlay(this.notes, handler);
    } else {
      TestAssets.playTempo(this.tempoNotes, 'piano', handler)
    }
  }
}

const PrimaryQuestionIntros = (that) => {
  let isSenior = false;
  if (
    that.page == "SeniorListeningPitch" ||
    that.page == "SeniorListeningInterval" ||
    that.page == "SeniorListeningHarmony"
  ) {
    isSenior = true;
  } else {
    isSenior = false;
  }

  return TestAssets.PrimaryQuestionIntros(that.page, isSenior);
};

const makeNumByPage = (that) => {
  let num = 1;
  if (
    that.page == "ListeningPitchPrimary" ||
    that.page == "SeniorListeningPitch"
  ) {
    // 根据页面定义音符数量，和音符类型
    const numBox = [1, 2, 4, 8, 16];
    num = numBox[that.level - 1];
  } else if (
    that.page == "SeniorListeningInterval" ||
    that.page == "ListeningIntervalPrimary"
  ) {
    num = 2;
  } else if (
    that.page == "ListeningHarmonyPrimary" ||
    that.page == "SeniorListeningHarmony"
  ) {
    // 根据页面定义音符数量，和音符类型
    num = 3;
  } else {
    num = 1;
  }

  return num;
};

const makeTempoNotes = (notes, num) => {
  const regex = /\d+/g; // 数字筛选的正则表达式
  let tempoNotes = notes.map(item => {
    const n = item.match(regex)
    return [{
      keys: [item.replace(n, '/' + n)],
      duration: num + '',
    }]
  })

  if (num == 8) {
    let box = []
    for (let i = 0; i < tempoNotes.length; i += 2) {
      box.push(tempoNotes.slice(i, i + 2));
    }

    tempoNotes = box.map(item => {
      return [...item[0], ...item[1]]
    })
  } else if (num == 16) {
    let box = []
    for (let i = 0; i < tempoNotes.length; i += 4) {
      box.push(tempoNotes.slice(i, i + 4));
    }

    tempoNotes = box.map(item => {
      return [...item[0], ...item[1], ...item[2], ...item[3]]
    })
  }

  return tempoNotes;
}
