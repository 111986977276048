<template>
  <div class="studio-adver" style="background-image: url(https://www.duoyinchina.com/assits/images/composerbackground.jpg); background-size: cover;">
    <div class="adver-box">
      <div class="info-box">
        <div class="info">
          <div>
            <div class="title">多音版权音乐工作室</div>
            <div class="sub-title">Polyphonic Music Studio</div>
            <div class="content-info">
              我们专注于原创流行音乐、游戏配乐、电影原声带，以及专业的音效的制作。无论是录音还是混音，我们都致力于将您需要喔的声音完美呈现。创意与专业，只为您的听觉盛宴！
            </div>
          </div>
        </div>

        <div class="info-bottom">
          <ButtonAdver title="我要作曲" name="arrow-right" @onclick="isOpenQrCode=true"></ButtonAdver>
        </div>

      </div>
    </div>
  </div>
  <QRCodeDialog :isOpen="isOpenQrCode" @close="isOpenQrCode=false"></QRCodeDialog>
</template>

<script>
/* eslint-disable */
import ButtonAdver from "@/components/button-adver.vue"
import QRCodeDialog from "@/components/QRCodeDialog.vue";

export default {
  name: 'SwiperPage',
  components: {
    ButtonAdver,
    QRCodeDialog
  },
  data(){
    return {
      isLoaded: false,
      isOpenQrCode: false,
    }
  }
}
</script>

<style lang="less" scoped>
@import "/src//style/root.less";
.studio-adver {   
  width: 100vw;
  height: 860px;
  display: flex;
  justify-content: center;

  .adver-box {
    width: var(--pc-content-width);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

  .info-box {
    width: 600px;
    height: 400px;
    display: flex;
    flex-direction: column;
    position: relative;

    .info {
      width: 500px;
      height: 300px;

      .title {
        font-size: 48px;
        font-weight: 500;
        color: #ffffff;
      }

      .sub-title {
        font-size: 17px;
        margin-top: 16px;
        color: #ffffffee;
      }

      .content-info {
        margin-top: 16px;
        text-align: left;
        font-size: 17px;
        color: #ffffffee;
      }
    }
  }
}


}
</style>