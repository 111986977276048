/* eslint-disable */ 
import axios from 'axios';

export const wxGet = (url, params=null) => {
    return new Promise((resolve, reject) => {
        const path = params!=null ? url + '?' + params : url;
        axios.get(path)
        .then(response => {
            resolve(response.data);
        })
        .catch((err) => {
            reject(err);
        });
    })
};