<template>
  <div class="post-info-box">

    <div class="info-box">
      <div class="info">
        <div>
          <div class="title">音乐基础理论知识与素养等级考试-渐进式学习题库</div>
          <div class="sub-title">Music Basic Theory and Literacy Level Exam - Progressive Learning QB.</div>
          <div class="content-info">
            多音音乐基础理论知识与素养等级考试-渐进式学习题库，专为学习音乐基础知识和应对各类音乐等级考试而设计！让您可以从“零”起步，渐进式学习，由基础开始探索音乐理论。这是您学习音乐的好帮手，让我们一起在题库中开启音乐知识之旅吧！
          </div>
        </div>
      </div>
      <div class="info-bottom">
        <ButtonAdver title="我要学习" name="arrow-right" @onclick="linkTo('/musicFoundation/NotePitchSing')"></ButtonAdver>
        <!-- <div class="low-ask">津ICP备19008556号-1</div> -->
      </div>
    </div>

    <div style="position: absolute; z-index: 1; pointer-events: visiblePainted; width: 100vw; height: 100vh; overflow: hidden;"> 
      <iframe allow-same-origin sandbox="allow-scripts allow-top-navigation" scrolling="no" width="1980" height="1440" frameBorder="0"
        allowTransparency="true" src="https://www.duoyinchina.com/assits/html/moveStars/"></iframe>
    </div>
    
  </div>
 
</template>

<script>
/* eslint-disable */
import ButtonAdver from "@/components/button-adver.vue"

export default {
  name: 'SwiperPage',
  components: {
    ButtonAdver
  },
  methods: {
    linkTo(e){
      this.$router.push(e)
    }
  }
}
</script>

<style lang="less">

.post-info-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;


  .info-box {
    width: 600px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .info {
      width: 800px;
      height: 300px;

      .title {
        font-size: 32px;
        font-weight: 500;
        text-align: center;
      }

      .sub-title {
        font-size: 17px;
        text-align: center;
        font-size: 18px;
        margin-top: 26px;
      }

      .content-info {
        margin-top: 30px;
        text-align: left;
        font-size: 17px;
      }
    }

    .info-bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 200px;
      height: 100px;
      position: absolute;
      z-index: 2;
      bottom: 0;
      pointer-events: auto;
    }
  }
}
</style>