<template>
    <!-- 更新或是添加任务 -->
    <t-dialog v-model:visible="isOpenDialog" :width="300" :on-close="getClose" :cancelBtn="null" :confirmBtn="null">
        <template #header>
            <p>官方微信</p>
        </template>

        <t-image src="https://www.duoyinchina.com/assits/images/wechat.jpg" ></t-image>

    </t-dialog>

</template>

<script>


export default {
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        }
    },
    watch: {
        isOpen: {
            immediate: true,
            handler(val) {
                this.isOpenDialog = val;
            }
        }
    },
    data() {
        return {
            isOpenDialog: false,
        }
    },
    methods: {
        getClose() {
            this.$emit('close');
        }
    }
}
</script>