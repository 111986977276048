<template>
    <div
        style="background-image: url(https://www.duoyinchina.com/assits/images/录音棚背景灰色.png); background-attachment: fixed; background-size: cover;">
        <div class="production-business">

            <div class="original-music">
                <div class="original-music-step">
                    <t-steps layout="vertical" current="FINISH">
                        <t-step-item class="step-item" style="color: #ffffff;" v-for="(item, index) in originalMusicStep" :key="index">

                            <template #icon>
                                <t-icon :name="item.icon" style="color: #ffffff;"></t-icon>
                            </template>

                            <template #title>
                                <span style="font-size: 24px; color: #ffffff; font-weight: 500;">{{ item.title
                                    }}</span>
                            </template>

                            <template #content>
                                <p style="margin: 16px 0; font-size: 16px; color: #ffffff;">{{ item.content }}</p>
                            </template>

                            <template v-if="item.extra" #extra>
                                <t-button size="medium" variant="base" style="margin: 16px 0;" @click="item.extra.handler">
                                    {{ item.extra.butTitle }}
                                    <template #suffix>
                                        <t-icon :name="item.extra.icon"></t-icon>
                                    </template>
                                </t-button>
                            </template>
                        </t-step-item>

                    </t-steps>
                </div>
            </div>
        </div>
    </div>
    <QRCodeDialog :isOpen="isOpenQrCode" @close="isOpenQrCode=false"></QRCodeDialog>
</template>


<script>
import QRCodeDialog from '@/components/QRCodeDialog.vue';

export default {
    data() {
        return {
            isOpenQrCode: false,
            originalMusicStep: [
                {
                    title: '联系在线音乐人',
                    content: '参考流行趋势与市场需求，深入探讨音乐风格、主题、伴奏样式、歌词等核心要素，确定原创流行音乐需求文件。',
                    icon: 'service',
                    extra: {
                        butTitle: '联系在线音乐人',
                        icon: 'arrow-right',
                        handler: this.openOnLineMusician
                    }
                },
                {
                    title: '签订音乐制作合同',
                    content: '依据已确定的音乐需求文件，签署音乐制作合同与保密协议，明确项目的制作周期、制作方式以及总体价格。并支付首付款。',
                    icon: 'pen',
                    extra: {
                        butTitle: '在线生成音乐制作合同',
                        icon: 'arrow-right'
                    }
                },
                {
                    title: '开始制作音乐小样',
                    content: '制作人应依据音乐需求文件草拟小样，并与需求方进行深入沟通以确认细节,若需调整，需求方应尽量再小样阶制作段提出调整要求。小样完成后，制作人将提供最多三次精细修订，确保作品达到预期标准。',
                    icon: 'music',
                    extra: {
                        butTitle: '查看我的音乐项目的制作进度',
                        icon: 'arrow-right'
                    }
                },
                {
                    title: '录制人声与乐器',
                    content: '在确认音乐需求后，进入专业录音阶段。首先预约录音棚，确保优质的录音环境。歌手需要进行声音热身和排练，以便在录音中展现最佳状态。制作人将在录音过程中提供指导和调整，确保人声效果符合预期。乐器方面，根据需求准备相应乐器并进行精确调校。乐手在制作人监督下完成录制，运用重叠录音等技巧提升音质和表现力。',
                    icon: 'microphone',
                    extra: {
                        butTitle: '录音棚预约',
                        icon: 'arrow-right'
                    }
                },
                {
                    title: '混音处理与母带制作',
                    content: '在混音阶段，我们将依据音乐风格细致调整音频参数，与客户协商确定混音风格和标准，以增强作品的质感和特色，确保达到卓越的听觉效果。完成混音后，我们将进行专业的母带处理，进一步提升音乐的整体品质。通过这一系列精心的制作过程，我们致力于交付一个音质上乘、满足客户要求的音乐作品。',
                    icon: 'sonic',
                    extra: {
                        butTitle: '查看我的音乐项目的混音进度',
                        icon: 'arrow-right'
                    }
                },
                {
                    title: '版权的申请与交接',
                    content: '完成音乐制作后，我们将进行版权申请，确保作品得到法律保护。在版权登记后，我们将与客户进行版权交接，交付所有相关的版权文件和音乐作品。同时，我们将根据合同条款进行尾款结算，确保双方的权益得到保障。通过清晰的交接流程和透明的结算过程，我们致力于为客户提供满意的服务，确保项目的顺利完结。',
                    icon: 'assignment',
                    extra: {
                        butTitle: '查看我的音乐项目的版权申请进度',
                        icon: 'arrow-right'
                    }
                },
                {
                    title: '作品分发与宣传',
                    content: '我们可提供作品的分发和宣传工作。音乐将被上传至主流音乐平台，包括网易云音乐、腾讯音乐和抖音音乐人，以确保作品能够触及更广泛的听众群体。同时通过专业的宣发方式提升音乐的在线可见度和点击率',
                    icon: 'video-camera-dollar',
                    extra: {
                        butTitle: '联系宣发人员',
                        icon: 'arrow-right'
                    }
                },

            ]
        }
    },
    components: {
        QRCodeDialog
    },
    methods: {
        openOnLineMusician(){
            this.isOpenQrCode = true;
        }
    }

}
</script>

<style lang="less" scoped>
@import url('/src/style/root.less');

.production-business {
    width: 100vw;
    display: flex;
    justify-content: center;
    background-color: #000000da;
    padding: 100px 0 64px;

    .original-music {
        width: var(--pc-content-width);
        position: relative;

        .original-music-step {
            width: 500px;
        }

    }
}
</style>