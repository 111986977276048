import NotePitchSing from "@/pages/MusicTheoryPages/music_foundation/components/NotePitch/NotePitchSing.vue";
import NotePitchName from "@/pages/MusicTheoryPages/music_foundation/components/NotePitch/NotePitchName.vue";
import NotePitchPrimary from "@/pages/MusicTheoryPages/music_foundation/components/NotePitch/NotePitchPrimary.vue";
import NoteRhythmCount from "@/pages/MusicTheoryPages/music_foundation/components/NoteRhythm/NoteRhythmCount.vue";
import NoteRhythmName from "@/pages/MusicTheoryPages/music_foundation/components/NoteRhythm/NoteRhythmName.vue";
import NoteInterval from "@/pages/MusicTheoryPages/music_foundation/components/NoteInterval/NoteInterval.vue";
import NotePitchTwelveEqualLawSing from "@/pages/MusicTheoryPages/music_foundation/components/NotePitch/NotePitchTwelveEqualLawSing.vue";
import NotePitchTwelveEqualLawName from "@/pages/MusicTheoryPages/music_foundation/components/NotePitch/NotePitchTwelveEqualLawName.vue";
import ListeningPitchPrimary from "@/pages/MusicTheoryPages/music_foundation/components/listeningPitch/ListeningPitchPrimary.vue";
import NoteIntervalSenior from "@/pages/MusicTheoryPages/music_foundation/components/NoteInterval/NoteIntervalSenior.vue";
import AreaMusic from "@/pages/MusicTheoryPages/music_foundation/components/KnowLedge/AreaMusic.vue";
import InstrumentMusic from "@/pages/MusicTheoryPages/music_foundation/components/KnowLedge/InstrumentMusic.vue";
import HumanMusic from "@/pages/MusicTheoryPages/music_foundation/components/KnowLedge/HumanMusic.vue";
import ForeignMusic from "@/pages/MusicTheoryPages/music_foundation/components/KnowLedge/ForeignMusic.vue";
import SeniorListeningPitch from "@/pages/MusicTheoryPages/music_foundation/components/listeningPitch/SeniorListeningPitch.vue";
import SeniorInstrumentMusic from "@/pages/MusicTheoryPages/music_foundation/components/KnowLedge/SeniorInstrumentMusic.vue";
import SeniorAreaMusic from "@/pages/MusicTheoryPages/music_foundation/components/KnowLedge/SeniorAreaMusic.vue";
import SeniorTypeMusic from "@/pages/MusicTheoryPages/music_foundation/components/KnowLedge/SeniorTypeMusic.vue";
import TempoMark from "@/pages/MusicTheoryPages/music_foundation/components/KnowLedge/TempoMark.vue";
import ListeningRhythmExercise from "@/pages/MusicTheoryPages/music_foundation/components/NoteRhythm/ListeningRhythmExercise.vue";
import ListeningIntervalPrimary from "@/pages/MusicTheoryPages/music_foundation/components/listeningPitch/ListeningIntervalPrimary.vue";
import ListeningHarmonyPrimary from "@/pages/MusicTheoryPages/music_foundation/components/listeningPitch/ListeningHarmonyPrimary.vue";
import SeniorListeningInterval from "@/pages/MusicTheoryPages/music_foundation/components/listeningPitch/SeniorListeningInterval.vue";
import SeniorListeningHarmony from "@/pages/MusicTheoryPages/music_foundation/components/listeningPitch/SeniorListeningHarmony.vue";
import ListeningRhythmPitchPrimary from "@/pages/MusicTheoryPages/music_foundation/components/listeningPitch/ListeningRhythmPitchPrimary.vue";
import ListeningRhythmPitchSenior from "@/pages/MusicTheoryPages/music_foundation/components/listeningPitch/ListeningRhythmPitchSenior.vue";
import NoteInterval12Low from "@/pages/MusicTheoryPages/music_foundation/components/NoteInterval/NoteInterval12low.vue";
import NoteSequencePrimary from "@/pages/MusicTheoryPages/music_foundation/components/NoteInterval/NoteSequencePrimary.vue";
import PitchRecognition from "@/pages/MusicTheoryPages/music_foundation/components/Primary/PitchRecognition.vue";
import ModeName from "@/pages/MusicTheoryPages/music_foundation/components/ModeName/ModeName.vue";
import TonicSolfa from "@/pages/MusicTheoryPages/music_foundation/components/ModeName/TonicSolfa.vue";
import SymbolRecognition from "@/pages/MusicTheoryPages/music_foundation/components/SymbolRecognition/SymbolRecognition.vue";
import DynamicMarkings from "@/pages/MusicTheoryPages/music_foundation/components/Primary/DynamicMarkings.vue";
import InstrumentRecognition from "@/pages/MusicTheoryPages/music_foundation/components/Primary/InstrumentRecognition.vue";
import SeniorInstrumentRecognition from "@/pages/MusicTheoryPages/music_foundation/components/Senior/SeniorInstrumentRecognition.vue";
import SeniorIChineseMusicStyleRecognition from "@/pages/MusicTheoryPages/music_foundation/components/Senior/SeniorIChineseMusicStyleRecognition.vue";
import SeniorListeningForeignMusic from "@/pages/MusicTheoryPages/music_foundation/components/Senior/SeniorListeningForeignMusic.vue";
import SeniorListeningForeignInstrumentMusic from "@/pages/MusicTheoryPages/music_foundation/components/Senior/SeniorListeningForeignInstrumentMusic.vue";
import SeniorHumanMusic from "@/pages/MusicTheoryPages/music_foundation/components/Senior/SeniorHumanMusic.vue";
import SeniorListeningChinesePopMusic from "@/pages/MusicTheoryPages/music_foundation/components/Senior/SeniorListeningChinesePopMusic.vue";
import SeniorMusicFormRecognition from "@/pages/MusicTheoryPages/music_foundation/components/Senior/SeniorMusicFormRecognition.vue";
import PitchNameRecognition from "@/pages/MusicTheoryPages/music_foundation/components/Primary/PitchNameRecognition.vue";
import SeniorModeNameRecognition from "@/pages/MusicTheoryPages/music_foundation/components/Senior/SeniorModeNameRecognition.vue"
import SeniorPitchNameRecognition from "@/pages/MusicTheoryPages/music_foundation/components/Senior/SeniorPitchNameRecognition.vue"
import SeniorPitchSingRecognition from "@/pages/MusicTheoryPages/music_foundation/components/Senior/SeniorPitchSingRecognition.vue"
import HiehgtLevelChineseMusicianRecognition from "@/pages/MusicTheoryPages/music_foundation/components/HightLevel/HiehgtLevelChineseMusicianRecognition.vue";
import HiehgtLevelForeignMusicianRecognition from "@/pages/MusicTheoryPages/music_foundation/components/HightLevel/HiehgtLevelForeignMusicianRecognition.vue";
import HeightLevelListeningFilmMusic from "@/pages/MusicTheoryPages/music_foundation/components/HightLevel/HeightLevelListeningFilmMusic.vue";
import HeightLevelListeningDanceMusic from "@/pages/MusicTheoryPages/music_foundation/components/HightLevel/HeightLevelListeningDanceMusic.vue";
import TempoMarkMusic from "@/pages/MusicTheoryPages/music_foundation/components/KnowLedge/TempoMarkMusic.vue";
import IdentifySimpleTimeSignature from "@/pages/MusicTheoryPages/music_foundation/components/KnowLedge/IdentifySimpleTimeSignature.vue";
import SeniorIdentifyThePerformingFormsMusic from "@/pages/MusicTheoryPages/music_foundation/components/Senior/SeniorIdentifyThePerformingFormsMusic.vue";
import SeniorIdentifyOfMusicTitle from "@/pages/MusicTheoryPages/music_foundation/components/Senior/SeniorIdentifyOfMusicTitle.vue";

export const RouterComponents = [
  {
    component: SeniorIdentifyOfMusicTitle,
    name: "SeniorIdentifyOfMusicTitle",
  },
  {
    component: SeniorIdentifyThePerformingFormsMusic,
    name: "SeniorIdentifyThePerformingFormsMusic",
  },
  {
    component: IdentifySimpleTimeSignature,
    name: "IdentifySimpleTimeSignature",
  },
  {
    component: TempoMarkMusic,
    name: "TempoMarkMusic",
  },
  {
    component: HeightLevelListeningDanceMusic,
    name: "HeightLevelListeningDanceMusic",
  },
  {
    component: HeightLevelListeningFilmMusic,
    name: "HeightLevelListeningFilmMusic",
  },
  {
    component: HiehgtLevelForeignMusicianRecognition,
    name: "HiehgtLevelForeignMusicianRecognition",
  },
  {
    component: HiehgtLevelChineseMusicianRecognition,
    name: "HiehgtLevelChineseMusicianRecognition",
  },
  {
    component: SeniorPitchNameRecognition,
    name: "SeniorPitchNameRecognition",
  },
  {
    component: SeniorPitchSingRecognition,
    name: "SeniorPitchSingRecognition",
  },
  {
    component: SeniorModeNameRecognition,
    name: "SeniorModeNameRecognition",
  },
  {
    component: PitchNameRecognition,
    name: "PitchNameRecognition",
  },
  {
    component: SeniorMusicFormRecognition,
    name: "SeniorMusicFormRecognition",
  },
  {
    component: SeniorListeningChinesePopMusic,
    name: "SeniorListeningChinesePopMusic",
  },
  {
    component: SeniorHumanMusic,
    name: "SeniorHumanMusic",
  },
  {
    component: InstrumentRecognition,
    name: "InstrumentRecognition",
  },
  {
    component: SeniorListeningForeignInstrumentMusic,
    name: "SeniorListeningForeignInstrumentMusic",
  },
  {
    component: SeniorListeningForeignMusic,
    name: "SeniorListeningForeignMusic",
  },
  {
    component: SeniorIChineseMusicStyleRecognition,
    name: "SeniorIChineseMusicStyleRecognition",
  },
  {
    component: SeniorInstrumentRecognition,
    name: "SeniorInstrumentRecognition",
  },
  {
    component: DynamicMarkings,
    name: "DynamicMarkings",
  },
  {
    component: SymbolRecognition,
    name: "SymbolRecognition",
  },
  {
    component: ModeName,
    name: "ModeName",
  },
  {
    component: TonicSolfa,
    name: "TonicSolfa",
  },
  {
    component: PitchRecognition,
    name: "PitchRecognition",
  },
  {
    component: NoteSequencePrimary,
    name: "NoteSequencePrimary",
  },
  {
    component: NoteInterval12Low,
    name: "NoteInterval12Low",
  },
  {
    component: ListeningRhythmPitchSenior,
    name: "ListeningRhythmPitchSenior",
  },
  {
    component: ListeningRhythmPitchPrimary,
    name: "ListeningRhythmPitchPrimary",
  },
  {
    component: NotePitchSing,
    name: "NotePitchSing",
  },
  {
    component: NotePitchName,
    name: "NotePitchName",
  },
  {
    component: NotePitchPrimary,
    name: "NotePitchPrimary",
  },
  {
    component: NoteRhythmName,
    name: "NoteRhythmName",
  },
  {
    component: ListeningRhythmExercise,
    name: "ListeningRhythmExercise",
  },
  {
    component: NoteRhythmCount,
    name: "NoteRhythmCount",
  },
  {
    component: NoteInterval,
    name: "NoteInterval",
  },
  {
    component: NotePitchTwelveEqualLawSing,
    name: "NotePitchTwelveEqualLawSing",
  },
  {
    component: NotePitchTwelveEqualLawName,
    name: "NotePitchTwelveEqualLawName",
  },
  {
    component: ListeningPitchPrimary,
    name: "ListeningPitchPrimary",
  },

  {
    component: ListeningHarmonyPrimary,
    name: "ListeningHarmonyPrimary",
  },
  {
    component: SeniorListeningInterval,
    name: "SeniorListeningInterval",
  },
  {
    component: SeniorListeningHarmony,
    name: "SeniorListeningHarmony",
  },
  {
    component: NoteIntervalSenior,
    name: "NoteIntervalSenior",
  },
  {
    component: AreaMusic,
    name: "AreaMusic",
  },
  {
    component: InstrumentMusic,
    name: "InstrumentMusic",
  },
  {
    component: HumanMusic,
    name: "HumanMusic",
  },
  {
    component: ForeignMusic,
    name: "ForeignMusic",
  },
  {
    component: SeniorListeningPitch,
    name: "SeniorListeningPitch",
  },
  {
    component: SeniorInstrumentMusic,
    name: "SeniorInstrumentMusic",
  },
  {
    component: SeniorAreaMusic,
    name: "SeniorAreaMusic",
  },
  {
    component: SeniorTypeMusic,
    name: "SeniorTypeMusic",
  },
  {
    component: TempoMark,
    name: "TempoMark",
  },
  {
    component: ListeningIntervalPrimary,
    name: "ListeningIntervalPrimary",
  },
];
